import React, { Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import CircularProgress from "components/CircularProgress";
import "react-placeholder/lib/reactPlaceholder.css";
import axios from 'axios';
import { config } from "../config";
// import "nprogress/nprogress.css";

export default function (importComponent) {

   Nprogress.configure({ showSpinner: false });
   Nprogress.start();

   class AsyncFunc extends Component {

      constructor(props) {
         super(props);
         this.state = {
            component: null
         };
      }

      componentWillUnmount() {
         this.mounted = false;
      }

      async componentDidMount() {
         this.mounted = true;
         const { default: Component } = await importComponent();
         const params = new URLSearchParams(window.location.search);
         const accessToken = params.get('access_token');
         if (accessToken) {
            await axios.post(`${config.api.url}/v1/user/session/update`, {}, {
               withCredentials: true,
               headers: { 
                  authorization: `Bearer ${accessToken}` 
               }
            });
            const location = window.location.pathname;
            window.history.replaceState(null, null, location);
         }
         Nprogress.done();
         if (this.mounted) {
            this.setState({
               component: <Component {...this.props} />
            });
         }
      }

      render() {
         const Component = this.props.loading ? <CircularProgress /> : this.state.component || <CircularProgress />;
         return (
            <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
               {Component}
            </ReactPlaceholder>
         );
      }
   }

   return AsyncFunc;
}
